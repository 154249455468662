import React from "react"
import AccountLayout from '../components/accountLayout'
import RedirectIn from '../components/redirectIn'
import SEO from '../components/seo'

const ThankYou = (props) => <AccountLayout message="You're all set!" instructions="Redirecting you to &quot;My Files&quot;...">
	<SEO title="Thank You"/>
	<RedirectIn to="/me" in={2000}/>
</AccountLayout>

export default ThankYou